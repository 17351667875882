/*!
 * SITE main application
 * @copyright Copyright 2023
 *
 * Goran Ilic, <ja@ich-mach-das.at>
 * Web: www.ich-mach-das.at
 */

// Core
import { create, boot } from 'core';
import * as utils from 'utils';

import doubleTapMenu from 'plugins/doubleTapMenu';
import hideNotificationAlerts from 'plugins/hideNotificationAlerts';
import imgLazyLoad from 'plugins/imgLazyLoad';
import initializeLightGallery from 'plugins/initializeLightGallery';
import loadOSMap from 'plugins/loadOSMaps';
import splideSlider from 'plugins/initializeSplide';
import processForm from 'plugins/formSubmitHandler';
import scrollToHash from 'plugins/scrollToHash';
import toggleHamburgerMenu from 'plugins/toggleHamburgerMenu';

// Import Vendor
import * as klaro from 'vendor/klaro-no-css';
import 'vendor/svgxuse';

//=============================================================
// Config
//=============================================================

const config = {};

//=============================================================
// Options
//=============================================================

const defaultOptions = {
  rootUrl: './',
};

//=============================================================
// Bind API
//=============================================================

const API = create(config);

API.use(doubleTapMenu);
API.use(hideNotificationAlerts);
API.use(imgLazyLoad);
API.use(initializeLightGallery);
API.use(loadOSMap);
API.use(processForm);
API.use(scrollToHash);
API.use(splideSlider);
API.use(toggleHamburgerMenu);

API.page = document.body.dataset;

API.init = (options) => {
  // Set runtime options
  API.options = Object.assign({}, defaultOptions, options);

  API.tasks.init.run();

  // Init gallery plugin
  const galleries = document.querySelectorAll('.items--images');
  for (let gallery of galleries) {
    API.initializeLightGallery(gallery);
  }

  // Splide slider

  API.splideSlider('#hero-slider', {
    type: 'fade',
    cover: true,
    interval: 5000,
    arrows: true,
    pagination: false,
    height: '70vh',
    preloadPages: 1,
  });

  // Handle form validation and submit
  const forms = document.querySelectorAll('.form-validate');
  forms.forEach((form) => {
    let errorList = form.querySelector('ul.alerts-validate');

    API.processForm(form, errorList).validateBeforeSubmit();

    if (form.classList.contains('form-ajax'))
      API.processForm(form, errorList).submitWithAjax();
  });

  // Form file attachment
  var inputs = document.querySelectorAll('.attachment');

  if (inputs) {
    Array.prototype.forEach.call(inputs, function (input) {
      var label = input.nextElementSibling,
        labelVal = label.innerHTML;

      input.addEventListener('change', function (e) {
        var fileName = '';

        if (this.files && this.files.length > 1) {
          fileName = (this.getAttribute('data-multiple-caption') || '').replace(
            '{count}',
            this.files.length
          );
        } else {
          fileName = e.target.value.split('\\').pop();
        }

        if (fileName) {
          label.nextElementSibling.innerHTML = fileName;
        } else {
          label.innerHTML = labelVal;
        }
      });
    });
  }

  // Init location map
  let manager = window.klaro.getManager(klaroConfig);
  const maps = document.querySelectorAll('.map');

  manager.watch({
    update: function (manager, eventType, data) {
      if (maps.length > 0 && manager.getConsent('leaflet')) {
        maps.forEach((item) => {
          let map = API.loadOSMap();
          let mapData = JSON.parse(item.dataset.map);

          let container = L.DomUtil.get('map');
          if (container != null) {
            container._leaflet_id = null;
          }

          map.createMap(item);
          map.addMarkers(mapData);
        });
      }
    },
  });
  if (maps.length > 0 && manager.getConsent('leaflet')) {
    maps.forEach((item) => {
      let map = API.loadOSMap();
      let mapData = JSON.parse(item.dataset.map);

      let container = L.DomUtil.get('map');
      if (container != null) {
        container._leaflet_id = null;
      }

      map.createMap(item);
      map.addMarkers(mapData);
    });
  }

  // Init mobile menu icon
  API.toggleHamburgerMenu('#menu-trigger', '#nav--main');

  API.tasks.ready.run();

  window.addEventListener('unhandledrejection', function (event) {
    console.warn(
      'WARNING: Unhandled promise rejection. Reason: ' + event.reason,
      event
    );
  });
};

//=============================================================
// Boot API
//=============================================================

boot(API);
